<template>
    <div
        :class="[
            'wheels-container',
            selected && 'selected'
        ]"
    >
        <div class="w-100 h-100 position-relative">
            <div
                v-if="getCoordinatesWheel1"
                class="position-absolute"
                :style="[
                    `width: ${getWheelPositioning.wheel1.width}%`,
                    `height: ${getWheelPositioning.wheel1.height}%`,
                    `top: ${getWheelPositioning.wheel1.top}%`,
                    `left: ${getWheelPositioning.wheel1.left}%`
                ]"
            >
                <img
                    v-show="image1Loaded"
                    :src="getWheelImageUrl(1)"
                    alt=""
                    class="w-100 h-100 wheel1"
                    :onerror="notShowWheels"
                    @load="wheelImageLoaded(1)"
                >
            </div>

            <div
                v-if="getCoordinatesWheel2"
                class="position-absolute"
                :style="[
                    `width: ${getWheelPositioning.wheel2.width}%`,
                    `height: ${getWheelPositioning.wheel2.height}%`,
                    `top: ${getWheelPositioning.wheel2.top}%`,
                    `left: ${getWheelPositioning.wheel2.left}%`
                ]"
            >
                <img
                    v-show="image2Loaded"
                    :src="getWheelImageUrl(2)"
                    alt=""
                    :class="['w-100 h-100 wheel2', angle === 1 && 'rotate-wheel']"
                    :onerror="notShowWheels"
                    @load="wheelImageLoaded(2)"
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VovWheels',
    data: function () {
        return {
            image1Loaded: false,
            image2Loaded: false,
            showWheels: true
        }
    },
    props: {
        wheelImages: Object,
        angle: Number,
        selected: Boolean,
        coordinates: Object,
        anglesImgCodes: Object,
        vehicleImageWheelDiameter: Object,
        wheelDiameter: Number
    },
    emits:[
        'showWheels',
        'loaded'
    ],
    computed: {
        getWheelPositioning: function () {
            const wheelScalingProportion = this.getWheelScalingProportion;
            const wheel1Coordinates = this.getCoordinatesWheel1;
            const wheel2Coordinates = this.getCoordinatesWheel2;
            const wheel1Size = this.getSizeWheel1;
            const wheel2Size = this.getSizeWheel2;
            const calculateProportion = ((size) => size * ((wheelScalingProportion - 1) / 2));

            if (!wheel1Coordinates || !wheel2Coordinates || !wheel1Size || !wheel2Size) {
                return {
                    wheel1: {width: 0, height: 0, top: 0, left: 0},
                    wheel2: {width: 0, height: 0, top: 0, left: 0}
                };
            }

            const widthScalingWheel1 = calculateProportion(wheel1Size[0]);
            const heightScalingWheel1 = calculateProportion(wheel1Size[1]);
            const widthScalingWheel2 = calculateProportion(wheel2Size[0]);
            const heightScalingWheel2 = calculateProportion(wheel2Size[1]);

            return {
                wheel1: {
                    width: wheel1Size[0] + widthScalingWheel1,
                    height: wheel1Size[1] + heightScalingWheel1,
                    left: wheel1Coordinates[0] - (widthScalingWheel1 / 2),
                    top: wheel1Coordinates[1] - (heightScalingWheel1 / 2)
                },
                wheel2: {
                    width: wheel2Size[0] + widthScalingWheel2,
                    height: wheel2Size[1] + heightScalingWheel2,
                    left: wheel2Coordinates[0] - (widthScalingWheel2 / 2),
                    top: wheel2Coordinates[1] - (heightScalingWheel2 / 2)
                }
            };
        },
        getCoordinatesWheel1: function () {
            if (this.coordinates) {
                if (this.coordinates[0]) {
                    const coordinates = this.coordinates[0];
                    if (coordinates[0] && coordinates[1] && coordinates[2] && coordinates[3]) {
                        return [
                            coordinates[0] * 100,
                            coordinates[1] * 100,
                            coordinates[2] * 100,
                            coordinates[3] * 100
                        ]
                    }
                }
            }
            return null;
        },
        getCoordinatesWheel2: function () {
            if (this.coordinates) {
                if (this.coordinates[1]) {
                    const coordinates = this.coordinates[1];
                    if (coordinates[0] && coordinates[1] && coordinates[2] && coordinates[3]) {
                        return [
                            coordinates[0] * 100,
                            coordinates[1] * 100,
                            coordinates[2] * 100,
                            coordinates[3] * 100
                        ]
                    }
                }
            }
            return null;
        },
        getSizeWheel1: function () {
            const coordinates = this.getCoordinatesWheel1;
            if (coordinates) {
                return [
                    coordinates[2] - coordinates[0],
                    coordinates[3] - coordinates[1],
                ]
            }

            return null;
        },
        getSizeWheel2: function () {
            const coordinates = this.getCoordinatesWheel2;
            if (coordinates) {
                return [
                    coordinates[2] - coordinates[0],
                    coordinates[3] - coordinates[1],
                ]
            }

            return null;
        },
        getWheelScalingProportion: function () {
            let wheelDiameter = this.wheelDiameter || null;

            if (!wheelDiameter || !this.vehicleImageWheelDiameter) return 1;

            if (wheelDiameter < this.vehicleImageWheelDiameter.Front) wheelDiameter = this.vehicleImageWheelDiameter.Front;
            if (wheelDiameter > this.vehicleImageWheelDiameter.FrontMax) wheelDiameter = this.vehicleImageWheelDiameter.FrontMax;

            return (wheelDiameter / this.vehicleImageWheelDiameter.Front) || 1;
        }
    },
    methods:{
        notShowWheels: function () {
            if (this.showWheels) {
                this.showWheels = false;
            }
        },
        wheelImageLoaded: function (number) {
            if (number === 1) {
                this.image1Loaded = true;
            }
            if (number === 2) {
                this.image2Loaded = true;
            }
        },
        getWheelImageUrl: function (wheelNumber) {
            if (this.anglesImgCodes && this.wheelImages.imgBaseUrl && this.wheelImages.images) {
                const wheelIndex = wheelNumber - 1;
                let path = this.wheelImages.images[`Img${this.anglesImgCodes[wheelIndex]}`];

                if (path) {
                    return `${this.wheelImages.imgBaseUrl}${path}`;
                }
            } 
            return '';
        }
    },
    watch: {
        wheelImages: function (newVal, oldVal) {
            try {
                let same = false;
                if (oldVal && newVal) {
                    if (
                        newVal.images[`Img${this.anglesImgCodes[0]}`] == oldVal.images[`Img${this.anglesImgCodes[0]}`] &&
                        newVal.images[`Img${this.anglesImgCodes[1]}`] == oldVal.images[`Img${this.anglesImgCodes[1]}`]
                    ) {
                        same = true;
                        this.image1Loaded = true;
                        this.image2Loaded = true;
                        this.$emit('loaded');
                        this.$emit('showWheels', true);
                    }
                }

                if (!same) {
                    this.showWheels = true;
                    this.image1Loaded = false;
                    this.image2Loaded = false;
                }
            } catch (error) {
                this.showWheels = true;
                this.image1Loaded = false;
                this.image2Loaded = false;
            }
        },
        showWheels: function (newVal) {
            this.$emit('showWheels', newVal);
        },
        image1Loaded: function (newVal) {
            if (newVal && this.image2Loaded) {
                this.$emit('loaded');
                this.$emit('showWheels', true);
            }
        },
        image2Loaded: function (newVal) {
            if (newVal && this.image1Loaded) {
                this.$emit('loaded');
                this.$emit('showWheels', true);
            }
        }
    }
}
</script>

<style scoped lang="scss">
img {
    position: absolute;
    top: 0;
    left: 0;
}

.wheels-container {
    height: 100%;
    width: 50%;

    .wheel1 {
        transform: rotate(2deg);
    }

    .wheel2 {
        transform: rotate(1deg);
    }

    &.selected {
        height: 100%;
        width: 100%;
    }
}

.rotate-wheel {
    transform: rotate(-30deg) !important;
}
</style>