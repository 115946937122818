<template>
    <div v-if="disableHistoryAction" class="d-flex justify-content-center align-items-center user-select-none toggle-active cursor-pointer text-muted rounded-circle go-back-button-container">
        <Icon icon-name="arrowLeft" :icon-weight="2.1"/>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center user-select-none toggle-active cursor-pointer text-muted rounded-circle go-back-button-container" @click="goBack">
        <Icon icon-name="arrowLeft" :icon-weight="2.1"/>
    </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'GoBackButton',
    components: {
        Icon
    },
    props: {
        disableHistoryAction: Boolean
    },
    methods: {
        goBack: function () {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped lang="scss">
.go-back-button-container {
    font-size: 32px;
    background-color: rgba($color: #000000, $alpha: .15);
    height: 38px;
    width: 38px;

    &:hover {
        background-color: rgba($color: #000000, $alpha: .20);
    }
}
    
@media only screen and (max-width: 576px) {
    .go-back-button-container {
        height: 32px;
        width: 32px;
        font-size: 24px;
    }
}
</style>
