<template>
    <div class="d-flex justify-content-center align-items-start pt-5 w-100 vh-100 overlay" @click.stop="closeOverlayByClickOutside">
        <div
            ref="overlayContentContainer"
            :class="[
                'overlay-content-container',
                'bg-brand-body',
                'rounded border border-color-brand-body',
                'shadow',
                'position-relative',
                !removePadding && 'p-3',
                hideOverflow && 'hide-overflow',
                $store.getters.device.isMobile && 'overlay-content-container-mobile'
            ]"
        >
            <div
                class="d-inline-block text-muted overlay-close-button cursor-pointer toggle-active"
                @click="closeOverlay"
            >
                <slot name="close-icon">
                    <Icon :class="[whiteButton && 'text-white']" icon-name="cancel"/>
                </slot>
            </div>
            <div
                :class="[
                    center && 'd-flex justify-content-center align-items-center',
                    hcenter && 'd-flex justify-content-center align-items-start',
                    vcenter && 'd-flex justify-content-start align-items-center',
                ]"
            >
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
    name: "Overlay",
    components: {
        Icon
    },
    emits: ['close'],
    props:{
        center: Boolean,
        hcenter: Boolean,
        vcenter: Boolean,
        removePadding: Boolean,
        hideOverflow: Boolean,
        whiteButton: Boolean
    },
    methods: {
        closeOverlayByClickOutside: function (event) {
            const content = this.$refs.overlayContentContainer;
            
            if (content) {
                const position = content.getBoundingClientRect();
                if (event.clientX < position.x ||
                    event.clientY < position.y ||
                    event.clientX > position.x + content.offsetWidth ||
                    event.clientY > position.y + content.offsetHeight
                ) {
                    this.closeOverlay();
                }
            }
        },
        closeOverlay: function () {
            this.$emit('close');
        }
    }
}
</script>

<style scoped lang="scss">
.overlay {
    background-color: rgba(#000000, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    &#{-content-container} {
        min-width: 200px;
        min-height: 100px;
        max-width: 96vw;
        max-height: 90vh;
        overflow: auto;

        &.overlay-content-container-mobile {
            max-height: 86vh;
        }

        &.hide-overflow {
            overflow: hidden;
        }
    }

    &#{-close-button} {
        position: absolute;
        top: .61rem;
        right: .61rem;
        z-index: 4;
        font-size: 20px;
    }
}
</style>
