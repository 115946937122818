<template>
    <div class="garage-card">
        <div class="text-start mb-1 garage-card-last-seen"><small class="text-brand-muted fw-regular">{{lastSeen()}} ...</small></div>

        <div
            class="garage-card-content bg-brand-light-gray p-3 rounded cursor-pointer toggle-active shadow-sm"
            @click.stop="clicked"
        >
            <div
                :class="[
                    'garage-card-drop-confirmation',
                    'd-flex flex-column justify-content-center align-items-center',
                    'rounded',
                    showDeleteConfirmation && 'show-confirmation'
                ]"
                @click.stop="showDeleteConfirmation = false"
            >
                <div class="mb-2"><small class="text-white">Please confirm to delete...</small></div>
                <div class="d-flex justify-content-around align-items-center w-75 user-select-none">
                    <div
                        class="bg-brand-primary text-white px-3 py-2 rounded shadow-sm fw-regular cursor-pointer toggle-active"
                        @click.stop="deleteVehicleFromGarage"
                    >DELETE</div>
                    <div
                        class="border border-color-brand-primary bg-brand-white text-brand-primary fw-regular px-3 py-2 rounded shadow-sm cursor-pointer toggle-active"
                        @click.stop="showDeleteConfirmation = false"
                    >CANCEL</div>
                </div>
            </div>

            <div class="garage-card-trash pe-2 pt-2"><IconButton class="shadow-sm" trash @activated="showDeleteConfirmation = true"/></div>

            <div class="d-flex justify-content-center user-select-none"><img :src="vehicle.img" alt="Vehicle Image" class="garage-card-image"></div>
            <div class="d-flex justify-content-between align-items-center fw-bold px-2">
                <small class="garage-card-vehicle-info text-brand-muted text-truncate w-75">{{vehicle.year}} {{vehicle.make}} {{vehicle.model}} {{vehicle.subModel}}</small>
                <small v-if="vehicle.drw" class="text-brand-muted fw-bold">DRW</small>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import IconButton from '@/components/shared/IconButton.vue';

export default {
    name: 'GarageCard',
    components: {
        IconButton
    },
    emits: [
        'clicked'
    ],
    props: {
        vehicle: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            showDeleteConfirmation: false
        };
    },
    methods: {
        lastSeen: function () {
            const duration = moment.duration(moment(new Date()).diff(moment(this.vehicle.lastSeen)));

            if (duration.minutes() < 1) {
                return `Last seen moments ago`;
            }

            if (duration.hours() < 1) {
                const time = parseInt(duration.minutes());
                return `Last seen ${time} minute${time > 1 ? `s`:''} ago`;
            }

            if (duration.days() < 1) {
                const time = parseInt(duration.hours());
                return `Last seen ${parseInt(duration.hours())} hour${time > 1 ? `s`:''} ago`;
            }

            const time = parseInt(duration.days());
            return `Last seen ${parseInt(duration.days())} day${time > 1 ? `s`:''} ago`;
        },
        clicked: function () {
            let path = `/builder?vehicle_id=${this.vehicle.id}`;

            this.$emit('clicked');
            this.$router.push(path);
        },
        deleteVehicleFromGarage: function () {
            const garage = [...this.$store.getters.vehiclesGarage];
            const idOnGarage = garage.findIndex(el => el.id === this.vehicle.id);

            if (idOnGarage >= 0) {
                delete garage[idOnGarage];
                this.$store.commit('vehiclesGarage', garage.filter(Boolean));
                this.showDeleteConfirmation = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.garage-card {
    &:hover {
        .garage-card-content {
            background-color: rgba($color: #000000, $alpha: .1);
            transition: background-color .3s ease;
            -o-transition: background-color .3s ease;
            -moz-transition: background-color .3s ease;
            -webkit-transition: background-color .3s ease;

            .garage-card-trash {
                opacity: 1;
            }

            .garage-card-vehicle-info {
                color: var(--client-color);
                transition: color .3s ease;
                -o-transition: color .3s ease;
                -moz-transition: color .3s ease;
                -webkit-transition: color .3s ease;
            }
        }
    }

    .garage-card-image {
        max-width: 350px;
        width: 100%;
    }

    .garage-card-content {
        position: relative;

        .garage-card-trash {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            transition: opacity .3s ease;
            -o-transition: opacity .3s ease;
            -moz-transition: opacity .3s ease;
            -webkit-transition: opacity .3s ease;
        }

        .garage-card-drop-confirmation {
            background-color: rgba($color: #000000, $alpha: .86);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: -999;
            transition: opacity .3s ease;
            -o-transition: opacity .3s ease;
            -moz-transition: opacity .3s ease;
            -webkit-transition: opacity .3s ease;

            &.show-confirmation {
                opacity: 1;
                z-index: 1;
            }
        }
    }
}
</style>