<template>

    <div
        :class="[
            'icon-container',
            'd-flex',
            'justify-content-end align-items-center',
            'w-100'
        ]"
    >
        <div
            :class="[
                'icon-toggler',
                'd-flex justify-content-center align-items-center',
                'border border-color-brand-primary rounded',
                'text-brand-primary',
                'cursor-pointer toggle-active user-select-none',
                'p-2 p-sm-0'
            ]"
            @click.stop="activated"
        >
            <Icon v-if="zoomIn" icon-name="zoomIn"/>
            <Icon v-if="zoomOut" icon-name="zoomOut"/>
            <Icon v-if="favorite" icon-name="starOutline"/>
            <Icon v-if="trash" icon-name="trash"/>
            <Icon v-if="car" icon-name="car"/>
            <Icon v-if="garage" icon-name="garage"/>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'ZoomButton',
    components: {
        Icon
    },
    emits: ['activated'],
    props: {
        favorite: Boolean,
        zoomIn: Boolean,
        zoomOut: Boolean,
        trash: Boolean,
        car: Boolean,
        garage: Boolean
    },
    methods: {
        activated: function () {
            this.$emit('activated');
        }
    }
}
</script>

<style scoped lang="scss">
.icon-container {
    // Same as in Options button
    .icon-toggler {
        height: 38px;
        max-height: 38px;
        width: 38px;
        max-width: 38px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 576px) {
    // Same as in Options button
    .icon-container {
        .icon-toggler {
            height: 30px;
            max-height: 30px;
            width: 30px;
            max-width: 30px;
        }
    }
}
</style>
