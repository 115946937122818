<template>
    <div v-if="open" class="position-relative">
        <Overlay
            center
            @close="close"
        >
            <div>
                <div class="d-flex justify-content-start align-items-center mb-3 border-bottom py-2 mx-4">
                    <div>
                        <h6 class="fw-medium my-0">GARAGE</h6>
                    </div>
                    <div v-if="hasVehiclesOnGarage" class="ms-4">
                        <IconButton trash @activated="showClearConfirmation = true"/>
                    </div>
                </div>
                <div v-if="hasVehiclesOnGarage" class="container-fluid garage-container">
                    <div
                        :class="[
                            'garage-clear-confirmation',
                            'rounded',
                            showClearConfirmation && 'show-confirmation'
                        ]"
                        @click.stop="showClearConfirmation = false"
                    >
                        <div class="garage-clear-confirmation-container d-flex flex-column justify-content-center align-items-center">
                            <div class="mb-2 text-center"><h5 class="text-white">Please confirm to clear the garage...</h5></div>
                            <div class="d-flex justify-content-center align-items-center w-75 user-select-none">
                                <div
                                    class="bg-brand-primary text-white px-4 py-2 rounded shadow-sm fw-regular cursor-pointer toggle-active"
                                    @click.stop="clearGarage"
                                >CLEAR</div>
                                <div
                                    class="border border-color-brand-primary bg-brand-white text-brand-primary fw-regular px-3 py-2 rounded shadow-sm ms-4 cursor-pointer toggle-active"
                                    @click.stop="showClearConfirmation = false"
                                >CANCEL</div>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-start align-items-start pb-3">
                        <div
                            v-for="(vehicle, key) in garage"
                            :key="key"
                            class="col-12 col-sm-6 col-lg-4 col-xxl-3 mb-4"
                        >
                            <GarageCard :vehicle="vehicle" @clicked="close"/>
                        </div>
                    </div>
                </div>

                <div v-else class="p-5 text-center garage-container">
                    <h6>Select vehicles to add it on the <b>garage</b></h6>
                </div>
            </div>
        </Overlay>
    </div>
</template>

<script>
import Overlay from '@/components/shared/Overlay.vue';
import IconButton from '@/components/shared/IconButton.vue';
import GarageCard from '@/components/builder/GarageCard.vue';

export default {
    name: "Garage",
    components: {
        Overlay,
        IconButton,
        GarageCard
    },
    emits: [
        'close'
    ],
    props: {
        open: {
            type: Boolean, 
            required: true
        },
        vehicleId: {
            type: Number,
            required: false
        }
    },
    data: function () {
        return {
            showClearConfirmation: false
        };
    },
    computed: {
        garage: function () {
            const garage = this.$store.getters.vehiclesGarage;
            garage.sort(function(a,b) {
                return new Date(b.lastSeen) - new Date(a.lastSeen);
            });
            return garage || [];
        },
        hasVehiclesOnGarage: function () {
            return this.garage.length > 0;
        }
    },
    methods: {
        clearGarage: function () {
            this.$store.commit('vehiclesGarage', []);
            this.showClearConfirmation = false;
            this.close();
        },
        close: function () {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
.garage-container {
    position: relative;
    width: 92vw;

    .garage-clear-confirmation {
        background-color: rgba($color: #000000, $alpha: .86);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: -999;
        transition: opacity .3s ease;
        -o-transition: opacity .3s ease;
        -moz-transition: opacity .3s ease;
        -webkit-transition: opacity .3s ease;

        &.show-confirmation {
            opacity: 1;
            z-index: 1;
        }

        .garage-clear-confirmation-container {
            height: 100%;
            max-height: 86vh;
        }
    }
}
</style>