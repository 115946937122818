<template>
    <div
        v-if="hasImagesRequired"
        :class="[
            'w-100',
            zoom && 'h-100',
        ]"
        @touchstart="touchStart"
    >
        <div
            :class="[
                'w-100',
                'd-flex justify-content-center align-items-center',
                'position-relative'
            ]"
        >            
            <div
                :class="[
                    'text-center position-relative',
                    selected ? 'w-100':'w-50'
                ]"
            >
                <img
                    v-show="imageLoaded"
                    :src="vehicleLargeImage"
                    alt=""
                    class="user-select-none w-100"
                    @load="vehicledImageLoaded"
                >

                <div
                    v-if="clicklable"
                    class="position-absolute w-100 h-100 cursor-pointer"
                    style="top: 0; left: 0; z-index: 1"
                    @click.stop="viewSelected"
                ></div>
            </div>

            <VovWheels
                v-show="imageLoaded"
                :class="[
                    'user-select-none',
                    'position-absolute'
                ]"
                :wheel-images="wheelImages"
                :angle="getViewAngle"
                :selected="selected"
                :coordinates="coordinates[getViewAngle] || null"
                :angles-img-codes="anglesImgCodes[getViewAngle] || null"
                :vehicleImageWheelDiameter="vehicleImageWheelDiameter"
                :wheelDiameter="wheelDiameter"
                @click.stop="viewSelected"
                @showWheels="showWheels"
                @loaded="wheelImagesLoaded"
            />
        </div>
    </div>
</template>

<script>
import VovWheels from "@/components/builder/VovWheels";

export default {
    name: 'VehicleImage',
    components: {
        VovWheels
    },
    emits: [
        'viewSelected',
        'showWheels',
        'touchstart',
        'imageLoaded'
    ],
    data: function () {
        return {
            notFirstLoad: false,
            wheelLoaded: false,
            vehicleLoaded: false,
            imageLoaded: false,
            hasImagesRequired: true
        }
    },
    props: {
        clicklable: Boolean,
        zoom: Boolean,
        selected: Boolean,
        coordinates: Object,
        viewSide: String,
        wheelImages: Object,
        anglesImgCodes: Object,
        vehicleSmallImage: String,
        vehicleLargeImage: {
            type: String,
            required: true
        },
        vehicleImageWheelDiameter: Object,
        wheelDiameter: Number
    },
    computed: {
        getViewAngle: function () {
            if (this.viewSide === 'front') {
                return 32;
            }
            if (this.viewSide === 'side') {
                return 1;
            }
            if (this.viewSide === 'back') {
                return 14;
            }
            return null;
        }
    },
    methods: {
        wheelImagesAreAvailable: function (wheelImages) {
            if (!this.anglesImgCodes || !wheelImages.images) return false;

            let allow = false;
            const angle = this.getViewAngle;
            
            if (!this.anglesImgCodes[angle]) return false;
            
            this.anglesImgCodes[angle].forEach(imgCode => {
                if (wheelImages.images[`Img${imgCode}`]) allow = true;
            });

            return allow;
        },
        vehicledImageLoaded: function () {
            this.vehicleLoaded = true;
            if (this.wheelLoaded) this.imageLoaded = true;
        },
        wheelImagesLoaded: function () {
            this.wheelLoaded = true;
            if (this.vehicleLoaded) this.imageLoaded = true;
        },
        viewSelected: function () {
            this.$emit('viewSelected');
        },
        showWheels: function (allow) {
            this.hasWheelImagesRequired = allow;
            this.$emit('showWheels', {view: this.viewSide, allow: allow});
        },
        touchStart: function (touchEvent) {
            this.$emit('touchstart', touchEvent);
        }
    },
    watch: {
        hasImagesRequired: function (newVal) {
            if (!newVal) {
                this.showWheels(false);
                this.$emit('imageLoaded', true);
            }
        },
        vehicleLargeImage: function (newVal) {
            this.vehicleLoaded = false;
            this.imageLoaded = false;
            this.hasImagesRequired = newVal && this.wheelImagesAreAvailable(this.wheelImages);
        },
        wheelImages: function (newVal) {
            this.wheelLoaded = false;
            this.imageLoaded = false;
            this.notFirstLoad = true;
            this.hasImagesRequired = this.vehicleLargeImage && this.wheelImagesAreAvailable(newVal);
        },
        imageLoaded: function (newVal) {
            this.$emit('imageLoaded', newVal);
        }
    },
    beforeMount: function () {
        this.hasImagesRequired = this.vehicleLargeImage && this.wheelImagesAreAvailable(this.wheelImages);
    }
}
</script>

<style scoped lang="scss">
img {
    height: auto;
}
</style>
